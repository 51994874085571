@font-face {
  font-family: "Larsseit";
  src: url("Larsseit-MediumItalic.eot");
  src: local("Larsseit Medium Italic"), local("Larsseit-MediumItalic"),
    url("Larsseit-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("Larsseit-MediumItalic.woff2") format("woff2"),
    url("Larsseit-MediumItalic.woff") format("woff"),
    url("Larsseit-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Larsseit";
  src: url("Larsseit-Thin.eot");
  src: local("Larsseit Thin"), local("Larsseit-Thin"),
    url("Larsseit-Thin.eot?#iefix") format("embedded-opentype"),
    url("Larsseit-Thin.woff2") format("woff2"),
    url("Larsseit-Thin.woff") format("woff"),
    url("Larsseit-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Larsseit";
  src: url("Larsseit-LightItalic.eot");
  src: local("Larsseit Light Italic"), local("Larsseit-LightItalic"),
    url("Larsseit-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("Larsseit-LightItalic.woff2") format("woff2"),
    url("Larsseit-LightItalic.woff") format("woff"),
    url("Larsseit-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Larsseit";
  src: url("Larsseit.eot");
  src: local("Larsseit"), url("Larsseit.eot?#iefix") format("embedded-opentype"),
    url("Larsseit.woff2") format("woff2"), url("Larsseit.woff") format("woff"),
    url("Larsseit.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Larsseit";
  src: url("Larsseit-ExtraBold.eot");
  src: local("Larsseit ExtraBold"), local("Larsseit-ExtraBold"),
    url("Larsseit-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("Larsseit-ExtraBold.woff2") format("woff2"),
    url("Larsseit-ExtraBold.woff") format("woff"),
    url("Larsseit-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Larsseit";
  src: url("Larsseit-BoldItalic.eot");
  src: local("Larsseit Bold Italic"), local("Larsseit-BoldItalic"),
    url("Larsseit-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("Larsseit-BoldItalic.woff2") format("woff2"),
    url("Larsseit-BoldItalic.woff") format("woff"),
    url("Larsseit-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Larsseit";
  src: url("Larsseit-Light.eot");
  src: local("Larsseit Light"), local("Larsseit-Light"),
    url("Larsseit-Light.eot?#iefix") format("embedded-opentype"),
    url("Larsseit-Light.woff2") format("woff2"),
    url("Larsseit-Light.woff") format("woff"),
    url("Larsseit-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Larsseit";
  src: url("Larsseit-Bold.eot");
  src: local("Larsseit Bold"), local("Larsseit-Bold"),
    url("Larsseit-Bold.eot?#iefix") format("embedded-opentype"),
    url("Larsseit-Bold.woff2") format("woff2"),
    url("Larsseit-Bold.woff") format("woff"),
    url("Larsseit-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Larsseit";
  src: url("Larsseit-Italic.eot");
  src: local("Larsseit Italic"), local("Larsseit-Italic"),
    url("Larsseit-Italic.eot?#iefix") format("embedded-opentype"),
    url("Larsseit-Italic.woff2") format("woff2"),
    url("Larsseit-Italic.woff") format("woff"),
    url("Larsseit-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Larsseit";
  src: url("Larsseit-Medium.eot");
  src: local("Larsseit Medium"), local("Larsseit-Medium"),
    url("Larsseit-Medium.eot?#iefix") format("embedded-opentype"),
    url("Larsseit-Medium.woff2") format("woff2"),
    url("Larsseit-Medium.woff") format("woff"),
    url("Larsseit-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Larsseit";
  src: url("Larsseit-ExtraBoldItalic.eot");
  src: local("Larsseit ExtraBold Italic"), local("Larsseit-ExtraBoldItalic"),
    url("Larsseit-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("Larsseit-ExtraBoldItalic.woff2") format("woff2"),
    url("Larsseit-ExtraBoldItalic.woff") format("woff"),
    url("Larsseit-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Larsseit";
  src: url("Larsseit-ThinItalic.eot");
  src: local("Larsseit Thin Italic"), local("Larsseit-ThinItalic"),
    url("Larsseit-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("Larsseit-ThinItalic.woff2") format("woff2"),
    url("Larsseit-ThinItalic.woff") format("woff"),
    url("Larsseit-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

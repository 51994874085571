.main {
  width: fit-content;
  display: flex;
  align-items: stretch;
  font-size: 16px;
  height: 30px;
}

.main svg {
  align-self: center;
  cursor: pointer;
  flex-shrink: 0;
}

.main svg.delete {
  color: #d32e2a;
}

.main svg.check {
  color: #fff;
  background-color: #00BA88;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 5px;
}

.main .mic {
  position: relative;
  top: 1px;
}

.main .audioPlayer {
  background: #1eccff;
  border-radius: 54px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13px;
  box-sizing: border-box;
  color: #fff;
}

.main .audioPlayer .timer {
  line-height: 21px;
  color: #fff;
  padding: 5px;
  margin: 0px 5px 0px 20px;
}

.main .fullControlCard {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.main .fullControlCard .middle {
  background: #1eccff;
  color: #fff;
  width: 251px;
  border-radius: 54px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.main .fullControlCard .middle aside {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main .fullControlCard .middle .middletxt {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  gap: 10px;
}

.main .record {
  color: #1eccff;
  background: #f5f5f8;
  border-radius: 54px;
  line-height: 21px;
  padding: 5px 13px 13px 11px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  max-width: 33px;
  transition: max-width 0.5s;
}

.main .record:hover {
  background: #1eccff;
  color: #fff;
  max-width: 178px;
}

.main .record .recordTxt {
  margin-left: 11px;
}

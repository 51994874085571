.starIcon {
  margin-bottom: 3%;
}

.scoreCard {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.starDescription {
  flex-grow: 1;
  justify-content: right;
  display: flex;
}

.scoreDescription {
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  color: #219653;
}

.scoreDescription.score-1,
.scoreDescription.score-2 {
  color: red;
}

.scoreCard label {
  font-weight: 300;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  /* line-height: 0; */
  box-sizing: border-box;
}

html,
body,
#root {
  padding: 0 !important;
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9dbe9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6e7191;
}
